<template>
    <modal
        class="store-modal"
        :adaptive="true"
        name="store-modal"
        height="auto"
        width="900"
        :pivot-y="isMobileScreen ? 1 : 0.5"
    >
        <div class="close-modal" @click="$modal.hide('store-modal')">
            <img src="@assets/img/icons/x.svg" alt="X" width="25px">
        </div>
        <store-links />
    </modal>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "StoreModal",
    components: {
        StoreLinks: () => import(/* webpackChunkName: "store-links" */ "@c/molecules/stores-links")
    },
    computed: {
        ...mapState({
            isMobileScreen: state => state.Application.isMobileScreen
        })
    }
}
</script>

<style lang="scss" scoped>
.store-modal {
    .close-modal {
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
        z-index: 3;

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;

            img {
                width: 15px;
            }
        }
    }

    /deep/ .vm--overlay {
        background: rgba(0, 0, 0, 0.5);
    }

    /deep/ .vm--modal {
        background-color: black;
    }
}
</style>
